export default {
  name: 'applicationHistory',
  props: ['applicationMode', 'cnReceiptId'],
  data() {
    return {
      appliedTransactionDetailsData: [],
      appliedTransactionDetailsFields: [
        {
          key: 'trx_number'
        },
        {
          key: 'trx_amount',
          class: 'text-right'
        },
        {
          key: 'application_amt',
          class: 'text-right'
        },
        {
          key: 'accounting_status'
        },
        {
          key: 'gl_date',
          label: 'App. GL Date'
        },
        {
          key: 'value_date'
        },
        {
          key: 'application_type'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_updated_by'
        },
        {
          key: 'last_updated_date'
        }
      ],
      downPayload: null,
    };
  },
  mounted() {
    this.getApplyUnApplyTransactionHistory();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.downPayload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'applyReceipt/getApplyUnApplyTransactionHistory',
            'Application History',
            () => (this.loader = false),
            this.appliedTransactionDetailsData
          );
        }
      }
    });
  },
  methods: {
    getApplyUnApplyTransactionHistory() {
      const payload = {
        receipt_hdr_id: this.cnReceiptId,
        application_mode_vset: this.applicationMode
      };
      this.downPayload = payload;
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getApplyUnApplyTransactionHistory', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.appliedTransactionDetailsData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
