import appStrings from '@/app/utility/string.utility';

export default {
  name: 'Category',
  props: [
    'partyRowDetails',
    'urlSummaryData',
    'workflowId',
    'dashboardDetails',
    'formName'
  ],
  watch: {},
  components: {},
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      vendorCategoryData: [
        { active: true, vendor_category: null, vendor_cat_id: 0 }
      ],
      vendorCategoryFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'vendor_category'
        }
      ],
      partyReqId: null,
      wfDtlId: null,
      eventId: null,
      approvalStatus: null,
      approvalStatusKey: null,
      // total: null,
      totalScore: null,
      rating: null,
      userId: null,
      showValueSetModal: false,
      index: null,
      seqOrder: null
    };
  },
  mounted() {
    if (this.partyRowDetails) {
      this.partyReqId = this.partyRowDetails.req_id;
      this.wfDtlId = this.partyRowDetails.inprocess_dtl_id;
      // this.eventId = this.workflowId.eventId;
      this.userId = this.workflowId.userId;
      // this.approvalStatus = this.partyRowDetails.approval_status;
    }
    if (this.urlSummaryData) {
      this.partyReqId = this.urlSummaryData.PARTY_REQ_ID;
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      this.eventId = this.urlSummaryData.wf_event_id;
      this.userId = this.urlSummaryData.wf_user_id;
      // this.approvalStatus = this.urlSummaryData.APPROVAL_STATUS;
    }
    if (this.dashboardDetails) {
      this.partyReqId = this.dashboardDetails.party_req_id;
      this.userId = this.dashboardDetails.wf_user_id;
    }
    // this.getVendorEvaluationDetails();
    // this.getPartyOnBoardingDetailsById();
    this.getVendorCategory();
    this.getPartyOnBoardingDetailsById();
  },
  methods: {
    editModeChange() {
      this.editMode = true;
    },
    addNewRow() {
      if (this.editMode) {
        this.vendorCategoryData.push({
          vendor_cat_id: 0,
          active: true,
          vendor_category: null
        });
      }
    },
    removeRow(index) {
      this.vendorCategoryData.splice(index, 1);
    },
    openValueSetModal(vsetCode, index) {
      this.index = index;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.VENDOR_CATEGORY) {
        let count = 0;
        for (let i of this.vendorCategoryData) {
          if (item.value_meaning === i.vendor_category) {
            count++;
          }
        }
        if (count > 0) {
          alert('Vendor Category cannot be same');
          if (this.vendorCategoryData[this.index].vendor_category === null) {
            this.vendorCategoryData.splice(this.index, 1);
          }
          this.vendorCategoryData[this.index].vendor_category = null;
        } else {
          this.vendorCategoryData[this.index].vendor_category =
            item.value_meaning;
        }
      }
    },
    postVendorCategory() {
      let count=0;
      for (let i of this.vendorCategoryData) {
            if(i.vendor_category === null || i.vendor_category === '') {
              count=count+1
            }
      }
        if(count>0) {
          alert('Vendor Category cant be null')
        } else {
          let vendorCategory = [];
          for (let i of this.vendorCategoryData) {
            vendorCategory.push({
              vendor_cat_id: i.vendor_category_id ? i.vendor_category_id : 0,
              vendor_category: i.vendor_category,
              active: i.active
            });
          }
          const payload = {
            partyReqId: this.partyReqId,
            vendor_cat: vendorCategory
          };
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/postVendorCategoryDetails', payload)
            .then(resp => {
              this.loader = false;
              if (resp.status === 201) {
                this.getVendorCategory();
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
    },
    getVendorCategory() {
      const payload = {
        partyReqId: this.partyReqId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getVendorCategoryDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            let result = resp.data.data;
            if (result.length > 0) {
              this.vendorCategoryData = result;
            } else {
              this.vendorCategoryData = [
                { active: true, vendor_category: null, vendor_cat_id: 0 }
              ];
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPartyOnBoardingDetailsById() {
      const payload = {
        party_req_id: this.partyReqId,
        user_id: this.userId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getPartyOnBoardingDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.seqOrder = resp.data.data.seq_order;
            this.approvalStatus = resp.data.data.approval_status;
            this.approvalStatusKey = resp.data.data.approval_status_key;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
