import appStrings from '@/app/utility/string.utility.js';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
import updateCrystalBillUpload from './updateCrystalBillUpload';
export default {
  name: 'crystalBillUpload',
  components: {
    DatePicker,
    updateCrystalBillUpload
    },
  watch: {
    currentPage: function() {
      this.getCrystalBillUploadData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCrystalBillUploadData();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  data() {
    return {
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      loader: false,
      fileName: null,
      fmsPrj: {
        text: null,
        value: null
      },
      status: {
        value: null,
        text: null
      },
      batchName: null,
      billPeriodFrom: null,
      billPeriodTo: null,
      showValueSetModal: false,
      unsubscribe: null,
      crystalBillUploadData: [],
      crystalBillUploadFields: [
        {
            key: 'batch_name'
        },
        {
          key: 'prj_name',
          label: 'Project Name'
        },
        {
          key: 'file_name'
        },
       
        {
          key: 'bill_date_from'
        },
        {
          key: 'bill_date_to'
        },
        {
          key:'batch_status'
        }
      ],
      showExcelUploadModal: false,
      showCrystalBatchModal: false,
      rowData: null,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: true,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'upload') {
          this.showExcelUploadModal = true;
        }
      }
    });
  },
  validations: {
    fmsPrj: {
      text: {
        required
      }
    }
  },
  methods: {
    getCrystalBillUploadData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          fms_prj: this.fmsPrj.value,
          status_flag: this.status.value,
          file_name: this.fileName ? this.fileName : null,
          batch_name: this.batchName ? this.batchName : null,
          billing_period_from: this.billPeriodFrom,
          billing_period_to: this.billPeriodTo
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTransaction/getcrystalBillUpload', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.crystalBillUploadData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.STATUS_FLAG) {
        this.status.text = item.value_meaning;
        this.status.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.fmsPrj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_CAT_VSET) {
        this.billCat.text = item.value_meaning;
        this.billCat.value = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.STATUS_FLAG) {
        this.status.text = null;
        this.status.value = null;
      } else if (vsetCode === this.fmsPrj.value) {
        this.fmsPrj.text = null;
        this.fmsPrj.value = null;
      }
    },
    clearFilters() {
      this.fileName = null;
      this.billPeriodFrom = null;
      this.billPeriodTo = null;
      this.status = { value: null, text: null };
      this.fmsPrj = { value: null, text: null };
      this.batchName = null;
      this.crystalBillUploadData = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.requestStatus = null;
    },
    closeCrystalBatchModal(flag) {
      this.showCrystalBatchModal = flag;
    },
    rowSelected(item) {
      this.showCrystalBatchModal = true;
      this.rowData = item;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
