import appStrings from '@/app/utility/string.utility';
import CryptoJS from 'crypto-js';
export default {
  name: 'ChangePasswordForm',
  data() {
    return {
      loader: false,
      unsubscribe: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      userName: null,
      // currentPassword: '',
      currentPassword: null,
      // newPassword: '',
      newPassword: null,
      // verifyNewPassword: '',
      verifyNewPassword: null,
      modalShow: false,
      showcurrentPassword: false,
      showNewPassword: false,
      showVerifyPassword: false
    };
  },
  mounted() {
    this.userName = this.getUserName();
  },
  methods: {
    getUserName() {
      const tempName = this.$store.state.auth.userName;
      return tempName;
    },
    togglecurrentPassword() {
      this.showcurrentPassword = !this.showcurrentPassword;
    },
    toggleNewPassword() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleVerifyPassword() {
      this.showVerifyPassword = !this.showVerifyPassword;
    },
    // handleOnChange: function(value) {
    //   this.newPassword = value;
    //   this.error = {
    //     setPassword: ''
    //   };
    //   this.reset = false;
    // },
    // handleOnComplete: function(value) {
    //   this.currentPassword = value;
    // },
    // handleOnCompleteSetPassword: function(value) {
    //   this.verifyNewPassword = value;
    //   if (this.verifyNewPassword != this.newPassword) {
    //     this.error = {
    //       setPassword: 'Password not match'
    //     };
    //     this.showAlert = true;
    //     this.isSuccess = false;
    //     this.responseMsg = this.error.setPassword;
    //   } else {
    //     this.changePassword();
    //   }
    // },
    changeCurrentPass: function() {
      if (this.verifyNewPassword != this.newPassword) {
        this.error = {
          setPassword: 'Password not match'
        };
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = this.error.setPassword;
      } else {
        this.changePassword();
      }
    },
    changePassword() {
      const payload = {
        old_password: this.encryptResponse(this.currentPassword),
        new_password: this.encryptResponse(this.newPassword)
      };
      this.loader = true;
      this.$store
        .dispatch('auth/changeExistingPassword', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.modalShow = true;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.currentPassword = null;
            this.newPassword = null;
            this.verifyNewPassword = null;
            this.responseMsg =
              response.response.data.message || appStrings.autoFailedMsg;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    encryptResponse(password) {
      const key = 'QUtDTUVGWEhKTEtMTVlaUA=='; // Base64 encoded key
      const keyForCryptoJS = CryptoJS.enc.Base64.parse(key);

      const encryptedData = CryptoJS.AES.encrypt(password, keyForCryptoJS, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return encryptedData.toString();
    },
    singout: function() {
      this.$store.dispatch('auth/logout').then(() => {
        this.leList = null;
        location.reload(true);
      });
    }
  }
};
