import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'loginHistory',
  components: {},
  props: ['rowData'],
  watch: {
    currentPage: function() {
      this.getLoginHistoryShowAllDtls();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getLoginHistoryShowAllDtls();
    },
    history: function() {
      if (this.history === 'single') {
        this.getLoginHistoryDtls();
      } else {
        this.getLoginHistoryShowAllDtls();
      }
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      loginHistoryData: [],
      loginHistoryFields: [
        {
          key: 'serial_number',
          label: 'Serial Number',
          class: 'text-center'
        },
        // {
        //   key: 'device_name',
        //   label: 'Device Name',
        //   class: 'text-center'
        // },
        {
          key: 'imei_1',
          label: 'IMEI-1',
          class: 'text-center'
        },
        {
          key: 'imei_2',
          label: 'IMEI-2',
          class: 'text-center'
        },
        {
          key: 'device_local_ip_address',
          label: 'Device Local IP Address',
          class: 'text-center'
        },
        {
          key: 'mac_address',
          label: 'Wi-Fi Mac Address',
          class: 'text-center'
        },
        {
          key: 'last_connected',
          label: 'Last Connected',
          class: 'text-center'
        },
        {
          key: 'connection_status',
          label: 'Status',
          class: 'text-center'
        },
        // {
        //   key: 'device_model_name',
        //   label: 'Model Name',
        //   class: 'text-center'
        // },
        // {
        //   key: 'device_ip_address',
        //   label: 'IP Address',
        //   class: 'text-center'
        // },
        {
          key: 'last_logged_in_center_id',
          label: 'Last Logged In Center ID',
          class: 'text-center'
        },
        {
          key: 'last_logged_in_center_name',
          label: 'Last Logged In Center Name',
          class: 'text-center minWidth'
        },
        {
          key: 'last_logged_in_user_id',
          label: 'Last Logged In User ID',
          class: 'text-center'
        },
        {
          key: 'employee_uuid',
          label: 'Employee UUID',
          class: 'text-center'
        },
        {
          key: 'employee_id',
          label: 'Employee ID',
          class: 'text-center'
        },
        {
          key: 'login_time_stamp',
          label: 'Login Time Stamp',
          class: 'text-center'
        },
        {
          key: 'logout_time_stamp',
          label: 'Logout Time Stamp',
          class: 'text-center'
        },
        {
          key: 'center_id',
          label: 'Center ID',
          class: 'text-center'
        },
        {
          key: 'center_name',
          label: 'Center Name',
          class: 'text-center'
        },
        {
          key: 'user_id',
          label: 'User ID',
          class: 'text-center'
        }
      ],
      history: 'single'
    };
  },
  mounted() {
    if (this.rowData) {
      this.getLoginHistoryDtls();
    }
  },
  methods: {
    getLoginHistoryDtls() {
      const payload = {
        serial_no: this.rowData.serial_no
        // serial_no: 'MT9055GL2WEXEH02582'
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getLoginHistoryData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const data = resp.data.data.map(item => {
              const extraInfo = Object.fromEntries(
                JSON.parse(item.extra_device_info).map(info => [
                  info.Name.toLowerCase().replace(/ /g, '_'),
                  info.Value
                ])
              );
              item.last_time_stamp=this.convertDateTime(item.last_time_stamp)
              return {
                connection_status: item.connection_status,
                serial_number: item.serial_number,
                device_id: item.device_id,
                device_name: item.device_name,
                device_model_name: item.device_model_name,
                device_ip_address: item.device_ip_address,
                device_local_ip_address: item.device_local_ip_address,
                mac_address: item.mac_address,
                last_logged_in_center_id: extraInfo.last_logged_in_center_id,
                login_time_stamp: extraInfo.login_time_stamp,
                last_logged_in_user_id: extraInfo.last_logged_in_user_id,
                last_logged_in_center_name:
                  extraInfo.last_logged_in_center_name,
                logout_time_stamp: extraInfo.logout_time_stamp,
                employee_uuid: extraInfo.employee_uuid,
                employee_id: extraInfo.employee_id,
                imei_1: item.imei,
                imei_2: item.imei_2,
                last_connected: item.last_time_stamp,
                center_name: 'NA',
                center_id: 'NA',
                user_id: 'NA',
                memory_storage_available: item.memory_storage_available
              };
            });
            this.loginHistoryData = data;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLoginHistoryShowAllDtls() {
      const payload = {
        // serial_no: 'MT9055GL2WEXEH02582',
        serial_no: this.rowData.serial_no,
        pagination: {
          _page: this.currentPage - 1,
          _limit: this.perPage
        }
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getLoginHistoryShowAllData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const data = resp.data.data.page.map(item => {
              const extraInfo = Object.fromEntries(
                JSON.parse(item.extra_device_info).map(info => [
                  info.Name.toLowerCase().replace(/ /g, '_'),
                  info.Value
                ])
              );
              item.last_time_stamp=this.convertDateTime(item.last_time_stamp)
              return {
                connection_status: item.connection_status,
                serial_number: item.serial_number,
                device_id: item.device_id,
                device_name: item.device_name,
                device_model_name: item.device_model_name,
                device_ip_address: item.device_ip_address,
                device_local_ip_address: item.device_local_ip_address,
                mac_address: item.mac_address,
                last_logged_in_center_id: extraInfo.last_logged_in_center_id,
                login_time_stamp: extraInfo.login_time_stamp,
                last_logged_in_user_id: extraInfo.last_logged_in_user_id,
                last_logged_in_center_name:
                  extraInfo.last_logged_in_center_name,
                logout_time_stamp: extraInfo.logout_time_stamp,
                employee_uuid: extraInfo.employee_uuid,
                employee_id: extraInfo.employee_id,
                imei_1: item.imei,
                imei_2: item.imei_2,
                last_connected: item.last_time_stamp,
                center_name: 'NA',
                center_id: 'NA',
                user_id: 'NA',
                memory_storage_available: item.memory_storage_available
              };
            });
            this.loginHistoryData = data;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    convertDateTime(inputDate) {
      let date = inputDate.substring(0, 10);  
      let time = inputDate.substring(10, 18);

   let formattedInput = `${date}T${time}`;
   const dateObj = new Date(formattedInput);
   const day = String(dateObj.getDate()).padStart(2, '0');
   const month = String(dateObj.getMonth() + 1).padStart(2, '0');
   const year = dateObj.getFullYear();
   
   const hours = String(dateObj.getHours()).padStart(2, '0');
   const minutes = String(dateObj.getMinutes()).padStart(2, '0');
   const seconds = String(dateObj.getSeconds()).padStart(2, '0');
   
   return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
   }
  }
};
