export default {
  name: 'extendRfp',
  props: ['rfphdrid', 'headerAllData', 'extendData'],
  data() {
    return {
      loading: false,
      showOpenExtendRfp: false,
      extendRfpEndDate: null,
      rfpExtensionId: 0,
      headerData: {
        rfp_status: null
      },
      newEndDate: null,
      extendRfpData: [],
      extendRfpFields: [
        {
          key: 'approval_status',
          class: 'col-fix text-center'
        },
        {
          key: 'le_id',
          class: 'col-fix text-center'
        },
        {
          key: 'old_end_date',
          class: 'col-fix text-center'
        },
        {
          key: 'new_end_date',
          class: 'col-fix text-center'
        },
        // {
        //   key: 'version',
        //   class: 'col-fix text-center'
        // }
      ],
      rfpHeaderId: null,
      popoverContent: null
    };
  },
  mounted() {
    if (this.headerAllData) {
      this.headerData = this.headerAllData;
      this.headerData.end_date = this.convertDateFormat(
        this.headerData.end_date
      );
    }
    if(this.extendData && this.extendData.length>0) {
      this.rfpExtensionId = this.extendData[0].rfp_ext_id;
      this.extendRfpData = this.extendData;
    }
    this.rfpHeaderId = this.rfphdrid;
  },
  methods: {
    extendRfp() {
      this.showOpenExtendRfp = true;
    },
    extendRfpModal(flag) {
      this.showOpenExtendRfp = flag;
    },
    clearDateTime(type) {
      if (type === 'extendRfpEndDate') {
        this.extendRfpEndDate = null;
      }
    },
    submitExtendRfp() {
      if(this.extendRfpEndDate) {
        const payload = {
          rfp_ext_id: this.rfpExtensionId ? this.rfpExtensionId : 0,
          rfp_header_id: this.rfphdrid,
          version: this.headerData.version,
          approval_status: this.headerData.approval_status,
          old_end_date: this.headerData.end_date,
          new_end_date: this.extendRfpEndDate,
          menu_id: this.$store.state.shared.menuId,
          le_id: this.headerData.le_id
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/extendRfp', payload)
          .then(response => {
            if (response.status === 200) {
              this.rfpExtensionId = response.data.data.rfp_ext_id;
              this.eventBus.$emit('rfpExtensionId', this.rfpExtensionId);
              this.responseMsg = response.data.message;
              this.makeToast(this.responseMsg, 'success');
              this.getExtendedRfpEndDate();
              this.showOpenExtendRfp = false;
            } else {
              this.responseMsg = response.response.data.message;
              this.makeToast(this.responseMsg, 'danger');
            }
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            this.makeToast(err, 'success');
          });
      } else {
        alert("Please select Extend RFPs End Date")
      }
      
    },
    getExtendedRfpEndDate() {
      const payload = {
        rfp_ext_id: this.rfpExtensionId
      };
      this.loading = true;
      this.extendRfpData = [];
      this.$store
        .dispatch('rfp/getExtendedRfpEndDate', payload)
        .then(response => {
          if (response.status === 200) {
            this.extendRfpData.push(response.data.data);
            this.newEndDate = response.data.data.new_end_date;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    convertDateFormat(inputDate) {
      const [datePart, timePart] = inputDate.split(' ');
      const [day, month, year] = datePart.split('-');
      const [hours, minutes, seconds] = timePart.split(':');
      const date = new Date(year, month - 1, day, hours, minutes, seconds);
      const formattedDate = date.toISOString().slice(0, 16);

      return formattedDate;
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: "custom-toast",
        autoHideDelay: 2000,
      });
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: this.$store.state.shared.menuId
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    }
  },
  beforeDestroy() {
    this.eventBus.$off('rfpExtensionId');
  }
};
