import Organization from '../../../../admin/organization';
import store from '../../../../../../store';

export default {
  name: 'PrintNfaApproval',
  props: ['propsSelectedGridData', 'wfMenuId'],
  components: {
    Organization,
    
  },
 

  data() {
    return {
      selectedGridData: null,
      negotiatedBy: 'N/A',
      negotiatedSummary: 'N/A',
      leAddress: 'N/A',
      entityArr: [{ unit_name: null }],
      recommendation:
        'In view of the above facts, we would like to recommend to waive off',
      waiverDistribution: [],
      waiverData: [],
      waiverOffAmt: null,
      suggestion: null,
      

      approvedItemList: [],
      approvedItemFields: [
        {
          key: 'nfa_number',
          label: 'NFA Number'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'item_code'
        },
        {
          key: 'item_description'
        },
        {
          key: 'last_purchase_price'
        },
        {
          key: 'nfa_zone'
        }
      ],
      awardSummaryList: [],
      awardSummaryFields: [
        {
          key: 'description',
          label: 'Item Description',
          class: 'item-desc'
        },
        {
          key: 'item_code',
          class: 'item-code'
        },
        {
          key: 'vendor_name',
          class: 'vendor-name'
        },
        {
          key: 'final_offer',
          class: 'final-offer'
        },
        {
          key: 'qty_required',
          class: 'qty-req'
        },
        {
          key: 'qty_awarded',
          class: 'qty-req'
        },
        {
          key: 'total_approved_amount',
          class: 'total-approved'
        },
        // {
        //   key: 'saving_percentage',
        //   label: 'Saving/Hike Percentage',
        //   class: 'saving-hike'
        // },
        {
          key: 'freight'
        },
        {
          key: 'payment_term_meaning',
          label: 'Payment Term',
          class: 'payment-term'
        },
        {
          key: 'tax_rate',
          label: 'Tax'
        },
        {
          key: 'sob',
          label: 'Business Share',
          class: 'business-share'
        },
        {
          key: 'additional_details',
          label: 'Add. Details',
          class: 'additonal'
        }
      ],
      approvalHistoryList: [],
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        },
        {
          key: 'seq_order'
        },
        {
          key: 'consultation_comments'
        },
        {
          key: 'action_date'
        }
      ],
      comparisonSheetList: [],
      comparisonSheetFields: [
        {
          key: 'vendor_name'
        },
        {
          key: 'description'
        },
        {
          key: 'item_code'
        },
        {
          key: 'initial_price',
          label: 'Initial Offer'
        },
        {
          key: 'final_offer'
        },
        {
          key: 'last_purchase_price',
          label: 'L.P.P.'
        },
        {
          key: 'saving',
          label: 'Saving(+/-)'
        },
        {
          key: 'saving_percentage',
          label: 'Saving % (+/-)'
        },
        {
          key: 'freight'
        },
        {
          key: 'payment_term_meaning',
          label: 'Pay Term'
        },
        // {
        //   key: 'approval_status',
        //   label: 'Approve'
        // },
        // {
        //   key: 'sob',
        //   label: 'S.O.B'
        // },
        {
          key: 'additional_details',
          label: 'Add. Details'
        }
      ],
      
    };
  },
  created() {
    this.selectedGridData = this.propsSelectedGridData;
    
    this.item = this.selectedGridData.le_id;
    this.getFetchItemDetails();
    this.getApprovedNfaPrice();
    this.getEventId();
    this.getComparisonSheetDetails(this.selectedGridData.nfa_hdr_id);
  },
  methods: {
    getFetchItemDetails() {
      const payload = {
        // recommended: this.$route.name !== 'nfaApp' ? 'yes' : 'no',
        nfa_hdr_id: this.selectedGridData.nfa_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getFetchItemDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.awardSummaryList = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getApprovedNfaPrice() {
      this.loader = true;
      this.$store
        .dispatch(
          'intWorkflow/getApprovedNfaPrice',
          this.propsSelectedGridData.nfa_hdr_id
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.approvedItemList = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEventId() {
      this.$store
        .dispatch(
          'intWorkflow/getEventId',
          store.state.shared.menuId || this.wfMenuId
        )
        .then(response => {
          if (response.status === 200) {
            this.eventId = response.data.data;
            this.getApprovalHistory(this.selectedGridData.nfa_hdr_id);
          }
        });
    },
    getApprovalHistory(nfa_hdr_id) {
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            nfaHdrId: nfa_hdr_id,
            approvalStatus: 'Y',
            eventId: this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    user.approver_employee_id === this.userInfo.employee_id &&
                    (user.approval_status === 'INPROCESS' ||
                      user.approval_status === 'CONSULT_INITIATED')
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
                const findConsultInitUser = this.approvalHistoryList.find(
                  data => data.approval_status === 'CONSULT_INITIATED'
                );
                this.isConsultInitiated = findConsultInitUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    setTableWidth(changes) {
      this.selectedGridData.body = changes.replaceAll(
        '<table>',
        '<table style="width: 100%">'
      );
    },

    getComparisonSheetDetails(nfaHdrId) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getComparisonSheetDetails', nfaHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.comparisonSheetList = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
