import commonHelper from '@/app/utility/common.helper.utility';
import responseModal from '../../../shared/responseModal';
import itemAttribute from './itemAttribute';
import uploadExcel from '@/app/components/shared/uploadExcel';
import URL_UTILITY from '../../../../utility/url.utility';

export default {
  name: 'item',
  props: ['itemAllData', 'rfphdrid', 'headerAllData', 'negotiationAllData'],
  components: {
    responseModal,
    itemAttribute,
    uploadExcel
  },
  watch: {
    itemCodeCurrentPage: function() {
      this.getItemCodeData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getItemCodeData();
    }
  },
  data() {
    return {
      editMode: true,
      loading: false,
      itemData: [
        {
          item_id: null,
          item_code: null,
          item_description: null,
          item_long_description: null,
          additional_information: null,
          quantity: null,
          uom: null,
          delete_flag: false,
          rfp_item_id: 0
        }
      ],
      itemFields: [
        {
          key: 'add_row',
          label: 'Add',
          stickyColumn: true,
          class: 'text-center'
        },
        // {
        //   key: 'remove_row',
        //   label: 'Remove',
        //   stickyColumn: true,
        //   class: 'text-center'
        // },
        {
          key: 'delete_flag',
          label: 'Delete',
          class: 'text-center'
        },
        {
          key: 'item_description',
          class: 'text-center'
        },
        {
          key: 'item_code',
          class: 'text-center'
        },
        {
          key: 'item_long_description',
          label: 'Item Specifiation',
          class: 'text-center'
        },
        {
          key: 'quantity',
          label: 'Quantity',
          class: 'text-center'
        },
        {
          key: 'uom',
          label: 'UOM',
          class: 'text-center'
        },
        {
          key: 'add_attribute',
          label: 'Add Attribute',
          class: 'text-center'
        },
        {
          key: 'itemAttributeData',
          class: 'text-center d-none'
        },
        {
          key: 'item_id',
          class: 'text-center d-none'
        }
      ],
      itemAttributeData: [
        {
          attribute_code: null,
          attribute_description: null,
          attribute_specification: null,
          weightage: null,
          delete_flag: false
        }
      ],
      itemAttributeFields: [
        { key: 'add', label: 'Add', stickyColumn: true, class: 'text-center' },
        {
          key: 'remove',
          label: 'Remove',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'attribute_code',
          label: 'Attribute Code',
          class: 'text-center'
        },
        {
          key: 'attribute_description',
          label: 'Attribute Description',
          class: 'text-center'
        },
        {
          key: 'attribute_specification',
          label: 'Attribute Specification',
          class: 'text-center'
        },
        {
          key: 'weightage',
          label: 'Weightage',
          class: 'text-center'
        },

        {
          key: 'weightage_percent',
          label: 'Weightage(%)',
          class: 'text-center'
        },
        {
          key: 'delete_flag',
          label: 'Delete',
          class: 'text-center'
        }
      ],
      showOpenLongDescriptionModal: false,
      longDescriptionIndex: null,
      itemLongDescription: null,
      addAttributeModal: false,
      addAttributeIndex: null,
      showOpenAdditionalInformationModal: false,
      attributeTableFlag: false,
      itemAttributeIndex: null,
      itemIndex: null,
      showOpenItemCode: false,
      itemCodeData: [],
      itemCodeFields: [
        {
          key: 'item_code'
        },
        {
          key: 'item_description'
        },
        {
          key: 'uom',
          label: 'UOM'
        }
      ],
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption(),
      itemCodeTotalRows: null,
      itemCodeCurrentPage: 1,
      rfpHeaderId: null,
      showOpenItemAttributeModal: false,
      rfpItemId: null,
      rfpType: null,
      getDataFlag: false,
      rfpItemFlag: false,
      rfpInitiateBidFlag: false,
      itemBasis: null,
      rfpClosedFlag: false,
      showUploadExcel: false,
      downloadRfpItemDocumentUrl: URL_UTILITY.getRfpItemSampleFile,
      popoverContent: null,
      errorMessage: null,
      index: null
    };
  },
  mounted() {
    this.rfpHeaderId = this.rfphdrid;
    if (this.itemAllData && this.itemAllData.length > 0) {
      this.getParentToChildData(this.itemAllData);
      this.rfpItemFlag = true;
      this.editMode = false;
    } else {
      this.rfpItemFlag = false;
    }
    this.eventBus.$emit('rfpItemFlag', this.rfpItemFlag);
    if (this.headerAllData) {
      this.itemBasis = this.headerAllData.rfp_basis;
      if (this.itemBasis === 'BOQ') {
        this.rfpInitiateBidFlag = true;
        this.editMode = false;
      }
    }
    if (this.negotiationAllData && this.negotiationAllData.length > 0) {
      this.rfpClosedFlag = true;
    }
  },
  created() {
    // this.eventBus.$on('itemCode', itemCodeResponse => {
    //   this.itemData[this.itemIndex].item_code = itemCodeResponse.item_code;
    //   this.itemData[this.itemIndex].item_description =
    //     itemCodeResponse.item_description;
    //   this.itemData[this.itemIndex].uom = itemCodeResponse.uom;
    //   this.itemData[this.itemIndex].item_id = itemCodeResponse.item_id;
    // });
    this.eventBus.$on('headerId', rfpHeaderId => {
      this.rfpHeaderId = rfpHeaderId;
    });
    this.eventBus.$on('rfpType', rfpType => {
      this.rfpType = rfpType;
    });
    this.eventBus.$on('attributeDataFlag', attributeDataFlag => {
      if (attributeDataFlag) {
        this.$emit('getHeaderData');
      }
    });
    this.eventBus.$on('rfpInitiateBidFlag', rfpInitiateBidFlag => {
      this.rfpInitiateBidFlag = rfpInitiateBidFlag;
      // if(this.rfpInitiateBidFlag) {
      //   this.editMode = false;
      // } else {
      //   this.editMode = true;
      // }
    });
    this.eventBus.$on('itemBasis', itemBasis => {
      this.itemBasis = itemBasis;
      if (this.itemBasis === 'BOQ') {
        this.rfpInitiateBidFlag = true;
        this.editMode = false;
      } else if (this.itemBasis === 'item') {
        this.rfpInitiateBidFlag = false;
      }
    });
    this.eventBus.$on('rfpClosedFlag', rfpClosedFlag => {
      this.rfpClosedFlag = rfpClosedFlag;
    });
  },
  methods: {
    addNewRow() {
      this.itemData.push({
        item_id: null,
        item_code: null,
        item_description: null,
        item_long_description: null,
        additional_information: null,
        quantity: null,
        uom: null,
        delete_flag: false,
        rfp_item_id: 0
      });
    },
    removeRow(index) {
      this.itemData.splice(index, 1);
    },
    hideLongDescriptionModal() {
      this.showOpenLongDescriptionModal = false;
    },
    openLongDescriptionModal(index) {
      this.itemLongDescription = this.itemData[index].item_long_description;
      this.longDescriptionIndex = index;
      this.showOpenLongDescriptionModal = true;
    },
    addItemLongDescription() {
      this.itemData[
        this.longDescriptionIndex
      ].item_long_description = this.itemLongDescription;
      this.showOpenLongDescriptionModal = false;
    },
    addAttributeInItem(id, index) {
      if (id === 0) {
        alert('Please save item first!');
      } else {
        this.itemIndex = index;
        this.rfpItemId = id;
        this.showOpenItemAttributeModal = true;
      }
    },
    openShowAddAttributeModal(flag) {
      this.addAttributeModal = flag;
    },
    deleteItem(item) {
      item.delete_flag = !item.delete_flag;
    },
    itemCodeModal(flag) {
      this.showOpenItemCode = flag;
    },
    openCloseResponseModal(item, index) {
      if (item === 'ITEM_CODE') {
        this.showOpenItemCode = true;
        this.itemIndex = index;
        // this.getItemCodeData();
      }
    },
    selectedItemCode(flag, selectedItem) {
      let count = 0;
      for (let i of this.itemData) {
        if (selectedItem.item_code === i.item_code) {
          count++;
        }
      }
      if (count > 0) {
        alert('Item already exist');
        this.itemData.splice(this.itemIndex, 1);
        this.showOpenItemCode = flag;
      } else {
        this.itemData[this.itemIndex].item_code = selectedItem.item_code;
        this.itemData[this.itemIndex].item_description =
          selectedItem.item_description;
        this.itemData[this.itemIndex].uom = selectedItem.uom;
        this.itemData[this.itemIndex].item_id = selectedItem.item_id;
        this.showOpenItemCode = flag;
      }
    },
    getItemCodeData() {
      const payload = {
        _page: this.itemCodeCurrentPage - 1,
        _limit: this.perPage,
        item_code: null
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getItemCodeData', payload)
        .then(response => {
          if (response.status === 200) {
            this.itemCodeData = response.data.data.page;
            this.itemCodeTotalRows = response.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    itemAttributeModal(flag) {
      this.showOpenItemAttributeModal = flag;
    },
    postItemData() {
      let itemdata = [];
      let selectQuantityCounter = 0;
      let quantityGreaterThanZeroCounter = 0;
      let itemDescriptionCounter = 0;
      for (let i of this.itemData) {
        if (
          (i.quantity === null || i.quantity === '') &&
          (this.rfpType === 'NRC_OE' || this.rfpType === 'NRC_CE') &&
          !i.delete_flag
        ) {
          selectQuantityCounter = selectQuantityCounter + 1;
        } else if (
          (i.quantity === 0 || i.quantity < 0) &&
          (this.rfpType === 'NRC_OE' || this.rfpType === 'NRC_CE') &&
          !i.delete_flag
        ) {
          quantityGreaterThanZeroCounter = quantityGreaterThanZeroCounter + 1;
        } else if (i.item_description === null && !i.delete_flag) {
          itemDescriptionCounter = itemDescriptionCounter + 1;
        } else {
          itemdata.push({
            rfp_item_id: i.rfp_item_id,
            item_id: i.item_id,
            item_code: i.item_code,
            item_description: i.item_description,
            item_long_description: i.item_long_description,
            uom: i.uom,
            quantity: i.quantity ? +i.quantity : null,
            delete_flag: i.delete_flag
          });
        }
      }
      if (selectQuantityCounter > 0) {
        alert('Please select Quantity');
      } else if (quantityGreaterThanZeroCounter > 0) {
        alert('Quantity should be greater than zero');
      } else if (itemDescriptionCounter > 0) {
        alert('Please select Item Description');
      } else if (itemdata.length > 0) {
        let payload = {
          rfp_header_id: this.rfpHeaderId,
          rfp_item_dtls: itemdata
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/postRfpItemData', payload)
          .then(response => {
            if (response.status === 200) {
              this.responseMsg = response.data.message;
              this.makeToast(this.responseMsg, 'success');
              this.getItemData();
              this.$emit('getHeaderData');
              this.editMode = false;
            } else {
              this.responseMsg = response.response.data.message;
              this.makeToast(this.responseMsg, 'danger');
            }
          })
          .catch(err => {
            this.loading = false;
            this.makeToast(err.message, 'danger');
          });
      }
    },
    getItemData() {
      let payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpItemData', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.itemData = result;
            this.getDataFlag = true;
            if (this.itemData.length === 0) {
              setTimeout(() => {
                this.itemData.push({
                  item_id: null,
                  item_code: null,
                  item_description: null,
                  item_long_description: null,
                  additional_information: null,
                  quantity: null,
                  uom: null,
                  delete_flag: false,
                  rfp_item_id: 0
                });
                this.rfpItemFlag = false;
                this.editMode = true;
              }, 500);
            } else {
              this.rfpItemFlag = true;
              this.editMode = false;
            }
            this.eventBus.$emit('rfpItemFlag', this.rfpItemFlag);
            setTimeout(() => {
              this.itemData = JSON.parse(JSON.stringify(this.itemData));
            }, 500);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast',
        autoHideDelay: 2000
      });
    },
    handleEditMode() {
      this.editMode = !this.editMode;
    },
    getParentToChildData(data) {
      if (data.length > 0) {
        this.itemData = data;
      }
    },
    clearValues(type, index) {
      if (type === 'ITEM_CODE') {
        this.itemData[index].item_code = null;
        this.itemData[index].item_description = null;
      }
    },
    qtyTwoDecimalValue(index, qty) {
      if (qty > 0) {
        let quantity = this.itemData[index].quantity.toString().split('.');
        // let withoutDecimal = this.itemData[index].quantity.toString();
        if (quantity.length > 1 && quantity[0].length > 14) {
          this.itemData[index].quantity = null;
          alert('Only Fourteen digits allowed before decimal');
        } else if (quantity.length > 1 && quantity[1].length > 5) {
          this.itemData[index].quantity = parseFloat(
            this.itemData[index].quantity
          ).toFixed(5);
          alert('Only Five Decimal Value Allowed.');
        } else if (quantity.length === 1 && quantity[0].length > 14) {
          this.itemData[index].quantity = null;
          alert('Only Fourteen digits allowed before decimal');
        }
      } else {
        this.editMode = false;
        alert('Quantity should be greater than 0');
        this.itemData[index].quantity = null;
      }
    },
    showHideUploadExcel(flag) {
      this.showUploadExcel = flag;
    },
    closeUploadExcelModal() {
      this.getItemData();
      this.showUploadExcel = false;
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: this.$store.state.shared.menuId
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    },
    checkBeforeAfterDecimal(qty) {
      if (qty.length >= 15 && qty[qty.length - 1] !== '.') {
        alert('Only decimal allowed after 14 digits');
        qty = qty.slice(15, -1);
      }
    }
  },
  beforeDestroy() {
    this.eventBus.$off('rfpItemFlag');
    this.eventBus.$off('headerId');
    this.eventBus.$off('itemBasis');
    this.eventBus.$off('rfpType');
  }
};
