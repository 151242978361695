import appStrings from '@/app/utility/string.utility';

export default {
  name: 'itemAttribute',
  props: [
    'rfpHeaderId',
    'rfpItemId',
    'headerAllData',
    'rfpInitiateBidFlag',
    'rfpClosedFlag',
    'itemIndex'
  ],
  data() {
    return {
      editMode: true,
      itemAttributeData: [
        {
          attribute_code: null,
          attribute_description: null,
          attribute_specification: null,
          weightage: null,
          delete_flag: false,
          item_attribute_id: 0
        }
      ],
      itemAttributeFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        { key: 'add', label: 'Add', stickyColumn: true, class: 'text-center' },
        // {
        //   key: 'remove',
        //   label: 'Remove',
        //   stickyColumn: true,
        //   class: 'text-center'
        // },
        {
          key: 'delete_flag',
          label: 'Delete',
          class: 'col-fix-medium'
        },
        {
          key: 'attribute_code',
          label: 'Attribute Code',
          class: 'text-center'
        },
        {
          key: 'attribute_description',
          label: 'Attribute Description',
          class: 'text-center'
        },
        {
          // key: 'attribute_specification',
          // label: 'Attribute Specification',
          // class: 'text-center'
        }
        // {
        //   key: 'weightage',
        //   label: 'Weightage',
        //   class: 'text-center'
        // },

        // {
        //   key: 'weightage_percent',
        //   label: 'Weightage(%)',
        //   class: 'text-center'
        // },
      ],
      headerId: null,
      rfp_item_id: null,
      attributeDataFlag: false,
      parent_value_set_id: null,
      showValueSetModal: false,
      vsetCode: null,
      index: null,
      showOpenCopyAttributesModal: false,
      copyAttributeData: [],
      copyAttributeFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1'
        },
        {
          key: 'item_code',
          class: 'col-fix text-center'
        },
        {
          key: 'item_description',
          class: 'col-fix text-center'
        }
      ],
      selectAllCheckBox: false,
      selectedRow: [],
      popoverContent: null
    };
  },
  mounted() {
    this.headerId = this.rfpHeaderId;
    this.rfp_item_id = this.rfpItemId;
    this.getItemAttributeData();
    if (this.itemData) {
      this.copyAttributeData = this.itemData.filter(
        el => el.rfp_item_id !== this.rfpItemId
      );
    }
  },
  methods: {
    addAttribute() {
      this.itemAttributeData.push({
        attribute_code: null,
        attribute_description: null,
        attribute_specification: null,
        weightage: null,
        delete_flag: false,
        item_attribute_id: 0
      });
    },
    removeAttribute(index) {
      this.itemAttributeData.splice(index, 1);
    },
    calculateWeightage() {},
    deleteItemAttribute(item) {
      item.delete_flag = !item.delete_flag;
    },
    postItemAttributeData() {
      if (this.headerId && this.rfp_item_id) {
        let itemattributedata = [];
        let count = 0;
        for (let i of this.itemAttributeData) {
          if (
            (i.attribute_code === null ||
              i.attribute_description === null ||
              i.attribute_description === '') &&
            !i.delete_flag
          ) {
            count++;
          }
          itemattributedata.push({
            item_attribute_id: i.item_attribute_id,
            attribute_code: i.attribute_code,
            attribute_description: i.attribute_description,
            attribute_specification: i.attribute_specification,
            weightage: i.weightage,
            delete_flag: i.delete_flag
          });
        }
        if (count > 0) {
          alert('Please fill in mandatory fields');
        } else {
          let payload = {
            rfp_header_id: this.headerId,
            rfp_item_id: this.rfp_item_id,
            rfp_item_attribute_dtls: itemattributedata
          };
          this.loading = true;
          this.$store
            .dispatch('rfp/postRfpItemAttributeData', payload)
            .then(response => {
              if (response.status === 200) {
                this.responseMsg = response.data.message;
                this.makeToast(this.responseMsg, 'success');
                this.getItemAttributeData();
              } else {
                this.responseMsg = response.response.data.message;
                this.makeToast(this.responseMsg, 'danger');
              }
            })
            .catch(err => {
              this.loading = false;
              this.makeToast(err.message, 'danger');
            });
        }
      } else {
        alert('Either header id or item id not found!');
      }
    },
    getItemAttributeData() {
      let payload = {
        rfpItemId: this.rfp_item_id
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpItemAttributeData', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.itemAttributeData = result;
            this.attributeDataFlag = true;
            this.eventBus.$emit('attributeDataFlag', this.attributeDataFlag);
            if (result.length === 0) {
              setTimeout(() => {
                this.itemAttributeData.push({
                  attribute_code: null,
                  attribute_description: null,
                  attribute_specification: null,
                  weightage: null,
                  delete_flag: false,
                  item_attribute_id: 0
                });
                this.editMode = true;
              }, 500);
            } else {
              this.editMode = false;
            }
            setTimeout(() => {
              this.itemAttributeData = JSON.parse(
                JSON.stringify(this.itemAttributeData)
              );
            }, 500);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast',
        autoHideDelay: 2000
      });
    },
    alterEditMode() {
      this.editMode = !this.editMode;
    },
    openValueSetModal(vsetCode, index) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.index = index;
      this.parent_value_set_id = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.RFP_ATTRIBUTES) {
        this.itemAttributeData[this.index].attribute_code = item.value_code;
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    copyAttributes() {
      const payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpDataByHeaderId', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data.rfp_item_dtls;
            this.copyAttributeData = result;
            this.copyAttributeData = this.copyAttributeData.filter(
              el => el.rfp_item_attribute_dtls.length === 0
            );
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      this.showOpenCopyAttributesModal = true;
    },
    copyAttributesModal(flag) {
      this.showOpenCopyAttributesModal = flag;
    },
    selectBoxChecked(flag, index, item, itemId) {
      this.copyAttributeData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
        if (this.selectedRow.length === this.copyAttributeData.length) {
          this.selectAllCheckBox = true;
        }
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.rfp_item_id !== itemId
        );
        this.selectAllCheckBox = false;
      }
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.copyAttributeData = this.copyAttributeData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.copyAttributeData;
      } else {
        this.copyAttributeData = this.copyAttributeData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
      }
    },
    copy() {
      let copyItemId = [];
      for (let i of this.selectedRow) {
        copyItemId.push({
          to_rfp_item_id: i.rfp_item_id
        });
      }
      let payload = {
        rfp_header_id: this.rfpHeaderId,
        from_rfp_item_id: this.rfpItemId,
        rfp_copy_atr_dtls: copyItemId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/postCopyAttributes', payload)
        .then(response => {
          if (response.status === 200) {
            this.responseMsg = response.data.message;
            this.makeToast(this.responseMsg, 'success');
            this.showOpenCopyAttributesModal = false;
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: this.$store.state.shared.menuId
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    },
    clearCode(index) {
      this.itemAttributeData[index].attribute_code = null;
      this.itemAttributeData[index].attribute_description = null;
    }
  },
  beforeDestroy() {
    this.eventBus.$off('attributeDataFlag');
  }
};
