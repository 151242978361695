import generateComparative from "./generateComparative";
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';

export default {
  name: 'round',
  props: ['roundProps', 'itemBasis', 'rfpHeaderId', 'negotiationSequence'],
  components: {
    generateComparative,
    UploadDocument
  },
  data() {
    return {
      roundData: [],
      roundFields: [
        {
          key: 'name',
          class: 'text-center'
        },
        {
          key: 'rfp_number',
          label: 'RFP Number',
          class: 'text-center'
        },
        {
          key: 'pan_no',
          class: 'text-center'
        },
        {
          key: 'email',
          class: 'text-center'
        },
        {
          key: 'bid_submit_status',
          label: 'Bid Status',
          class: 'text-center'
        },
        {
          key: 'view_document',
          class: 'text-center'
        }
      ],
      currentPage: 1,
      perPage: 50,
      showOpenGenerateComparativeModal: false,
      loading: false,
      rfpBasis: null,
      popoverContent: null,
      formType: null,
      openKmAuth: null,
      showHistory: false,
      historyType: null,
      editMode: true,
      isDisabled: false,
      documentList: [],
      selectedGridData: {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      bidHeaderId: null,
      userid: null,
      rfpType: {
        value: null,
        text: null
      },
      bidPendingFlag: false
    };
  },
  created() {
    this.eventBus.$on('userId', userId => {
      this.userid = userId;
    });
  },
  mounted() {
    if(this.rfpHeaderId) {
      this.getHeaderData(this.rfpHeaderId);
    }
    if (this.roundProps) {
      this.getRoundData(this.roundProps);
    }
    this.rfpBasis = this.itemBasis;
  },
  methods: {
    getRoundData(props) {
      let payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        negotiation_hdr_id: props.negotiation_hdr_id,
        rfp_header_id: props.rfp_header_id
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getNegotiationRoundData', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            let result = response.data.data.page;
            this.roundData = result;
            this.checkBidPending();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openGenerateComparativeModal() {
      this.showOpenGenerateComparativeModal = true;
    },
    generateComparativeModal(flag) {
      this.showOpenGenerateComparativeModal = flag;
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: this.$store.state.shared.menuId
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    },
    viewUploadedDocuments(item) {
      this.bidHeaderId = item.bid_hdr_id;
      this.formType = 'rfpBidDocument';
      this.getOpenKmAuthTokenUploadDocument();
    },
    getOpenKmAuthTokenUploadDocument() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideHistory(true, 'invoice');
              this.editMode = false;
              this.isDisabled = true;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
      // this.getUploadedDocDtl();
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: 100,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 89,
        record_id: this.bidHeaderId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.documentList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
              this.totalRows = response.data.data.total_elements;
            }
             else {
              // alert('Document not available for this record');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getHeaderData(rfpHdrId) {
      let payload = {
        headerId: rfpHdrId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpHeaderData', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.rfpType = {
              value: result.rfp_type,
              text: result.rfp_type_meaning
            };
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    checkBidPending() {
      let count=0;
      for(let i of this.roundData) {
         if(i.bid_submit_status === 'Bid Submitted') {
          count=count+1;
         }
      }
      if(count===this.roundData.length) {
        this.bidPendingFlag = true;
      }
    }
  }
};
